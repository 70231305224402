/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
// import Datetime from 'react-datetime';
import _ from 'lodash';
import 'isomorphic-fetch';
import { toast } from 'react-toastify';

import {
  CustomCheckbox, CheckMark, ButtonContainer, InviteContainer, InviteAmigo,
  NameContainer, ImageInput, ImageContentContainer, InputContainer, StartDateContainer, DateJoiner, CustomMenuItem, StyledDateTime,
  TitleContainer, FieldTitle, ImageContainer, AmigosContainer, TopContainer, ScrollableContainer, DateIconContainer,
  RemovePhotoButton, LocationContainer
} from '../CreateEvent/styles';
import { ActivityTypeContainer, ActivityDropdown, SurvivorAverage, SurvivorChallengeSection } from './styles';

import Waiting from '../Waiting';

import { getAllCompanies, saveChallengeData, getUserCompanyDetails, editChallengeLibrary, getSurveyLibraries, fetchCompanyTeamUsers } from '../../redux/actions';

import { getOrientation, resetOrientation, checkImage, getPermissionStatus } from '../../utils/methods';

import { updatedTierPoints, groupActivityTypesItems, activityTypesItems, activityChallengeTypes, challengeModeItems, showChallengeEmailNotificationInfo } from '../../../mockData';

import { imageErrorMessage, ImageUrl } from '../../utils/constants';
import { allowChallengeEventForSpouseDependent } from "../../redux/actions/userProfileActions";
import { RadioButtonContainer } from '../CreateRecipe/styles';
import RadioButton from "../CustomRadioButton";
import { FirstPart } from '../BehaviorChallenge/styles';
import { withTranslation } from 'react-i18next';
import { GetCompanyTeamUsers } from '../../redux/constants/apiConstants';
import { http } from '../../redux/http';
import CommonDropdown from '../common/CommonDropDown/CommonDropdown';
import LazyImage from '../common/LazyImage/LazyImage';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import CommonButton from '../common/CommonButton/CommonButton';
import Spinner from '../common/Spinner';
class CreateChallengeForm extends Component {

  constructor(props) {
    super();
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, createLibrary, /*role */} = props;
    let ImageName = '';
    if (!_.isEmpty(survivorChallengeData) && (props.activityChallengeById || props.editLibrary)) {
      const Array = survivorChallengeData.image.split('/');
      ImageName = Array[Array.length - 1];
    }
    let updatedTiers = updatedTierPoints;
    // role === 'WELLNESS_CHAMPION' && updatedTiers.splice(3);
    this.state = {
      title: _.get(survivorChallengeData, 'title', ''),
      date: _.get(survivorChallengeData, 'date', moment().add(1, 'days').format('MM/DD/YYYY')),
      endDate: _.get(survivorChallengeData, 'endDate', moment().add(1, 'days').format('MM/DD/YYYY')),
      description: _.get(survivorChallengeData, 'body', ''),
      imageName: ImageName,
      time: _.get(survivorChallengeData, 'time', moment().format('hh:mm a')),
      endTime: _.get(survivorChallengeData, 'endTime', moment().format('hh:mm a')),
      showDateTimeInput: '',
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: _.get(survivorChallengeData, 'challenge_point', '10'),
      challengeMode: createLibrary ? 'Solo' : _.get(survivorChallengeData, 'challenge_mode', ''),
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', 0),
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', 0),
      amigosToInvite: [],
      openDateTimePicker: '',
      imgSrc: _.get(survivorChallengeData, 'image', ''),
      companies: _.get(survivorChallengeData, 'companies', []),
      location: [],
      isSpouse: false,
      isDependent: false,
      isEmployee: false,
      challengePoints: updatedTiers,
      challengeLength: 2,
      activityChallengeType: createLibrary ? "Classic challenge" : survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      imageUpdated: 0,
      challengePointKey: 'Tier 1: 10 points',
      emailOption: {
        "launch_challenge_email": 1,
        "challenge_reminder_email": 1,
        "challenge_complete_email": 1
      },
      companiesPermission: false,
      locationPermission: false,
      selectedOption: 1,
      surveyId: '',
      surveyType: '',
      dailyGoal: _.get(survivorChallengeData, 'daily_goal', 0) || 0,
      selectedDailyGoalOption: 1,
      is_team_challenge:0,
      teams:[],
      allTeams:[],
      selectedCompany:{
        "id": 1,
        "company_name": "Woliba",
        "show_biometric": 1,
        "spouse_or_dependent": 1,
        "company_start_date": 2016,
        "show_events": 1,
        "show_education": 1,
        "show_employee_directory": 1 },
      isChallengeCreating:false
    }

    this.setIschallengeCreating = this.setIschallengeCreating.bind(this);
  }

  componentDidMount() {
    this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    const companiesPermission = getPermissionStatus("Show companies in challenge", this.props.userPermissions);
    const locationPermission = getPermissionStatus("Show locations in challenge", this.props.userPermissions);
    this.props.getSurveyLibraries();
    if (companiesPermission) {
      if (!this.props.allCompanies) {
        this.props.fetchAllCompanies();
      }
    } else {
      this.props.fetchCompanyForUser();
      this.props.allowChallengeEventForSpouseDependent();
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
    this.setState({
      companiesPermission: companiesPermission,
      locationPermission: locationPermission
    })
    document.addEventListener('mousedown', this.handleClick);

    if(this.props.role != "ADMIN"){
      this.getTeams(this.props.companyDetails.id);
    }else{
      this.onCompanySelect('selectedCompany', this.state.selectedCompany, this.state.selectedCompany['key'])
    }
  }
  fillProps = () => {
    const { survivorChallengeData, isGroupChallenge, isSurvivorChallenge, surveyLibraries } = this.props;
    const Array = survivorChallengeData.image.split('/');
    let ImageName = Array[Array.length - 1];
    let tierPointsIndex = this.state.challengePoints.findIndex((data) => data.value == _.get(survivorChallengeData, 'challenge_point', '10'));
    let surveyIndex = surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries.findIndex((data) => data.id === survivorChallengeData.survey_id) : -1;
    this.setState({
      title: _.get(survivorChallengeData, 'title'),
      date: _.get(survivorChallengeData, 'date', moment().add(1, 'days').format('MM/DD/YYYY')),
      endDate: _.get(survivorChallengeData, 'endDate', moment().add(1, 'days').format('MM/DD/YYYY')),
      description: _.get(survivorChallengeData, 'body'),
      activityType: isGroupChallenge ? survivorChallengeData.event_type === 'Total Steps' ? 'Total Group Steps' : survivorChallengeData.event_type === 'Total Calories' ? 'Total Group Calories' : 'Total Group Distance' : _.get(survivorChallengeData, 'event_type', 'Total Distance'),
      challengePointValue: tierPointsIndex == -1 ? 10 : _.get(survivorChallengeData, 'challenge_point', '5'),
      challengeMode: _.get(survivorChallengeData, 'challenge_mode') || 'Solo',
      weeklyIncrement: _.get(survivorChallengeData, 'weekly_increment', 0) || 0,
      survivorAverage: _.get(survivorChallengeData, 'survivor_average', 0) || 0,
      imgSrc: _.get(survivorChallengeData, 'image', ''),
      imageName: ImageName,
      survivorChallenge: isSurvivorChallenge,
      groupChallenge: isGroupChallenge,
      activityChallengeType: survivorChallengeData.challenge_type === 'new_group' ? 'Group Challenge' : survivorChallengeData.challenge_type === 'group' ? 'Classic challenge' : 'Survivor challenge',
      challengePointKey: updatedTierPoints[tierPointsIndex == -1 ? 0 : tierPointsIndex].key,
      surveyId: survivorChallengeData.survey_id,
      surveyType: surveyLibraries && surveyLibraries.length > 0 ? surveyLibraries[surveyIndex === -1 ? 0 : surveyIndex].survey_name : '',
      selectedOption: surveyIndex === -1 ? 1 : 0,
      dailyGoal: _.get(survivorChallengeData, 'daily_goal', 0) || 0,
      // selectedDailyGoalOption: this.state.dailyGoal ? 0 : 1
    })
  }
  componentDidUpdate(prevProps) {
    if (this.props.survivorChallengeData !== prevProps.survivorChallengeData || prevProps.surveyLibraries !== this.props.surveyLibraries) {
      this.props.activityChallengeById || this.props.editLibrary ? this.fillProps() : null;
    }
    if (!this.state.companiesPermission && this.props.userCompany !== prevProps.userCompany) {
      this.setState({
        companies: this.props.userCompany && [this.props.userCompany.id]
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  onChangeLocation = (id) => {
    const { location } = this.state;
    if (location.includes(id)) {
      let index = location.findIndex((item) => item === id);
      if (index > -1) {
        location.splice(index, 1);
      }
    } else {
      location.push(id);
    }
    this.setState({
      location: location
    })
  }

  selectAllLocation = (e, locationDetails) => {
    e.preventDefault();
    const { location } = this.state;
    if (location.length == locationDetails.length) {
      this.setState({
        location: []
      })
    }
    else {
      let arr = [];
      for (let i = 0; i < locationDetails.length; i++) {
        arr.push(locationDetails[i].id);
      }
      this.setState({
        location: arr
      })
    }
  }

  handleClick = (e) => {
    if (this.datePickerWrapper && !this.datePickerWrapper.contains(e.target)) {
      this.setState({
        openDateTimePicker: ''
      });
    }
  };

  setDatePickerWrapper = (node) => {
    this.datePickerWrapper = node;
  };

  onChange = (e) => {
    let file = e.target.files[0];
    if (checkImage(e.target.files)) {
      if ((file?.size / 1000000) <= 20) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const array = document.getElementById('create-challenge-file').value.split("\\");
        reader.onloadend = () => {
          this.setState({
            imgSrc: reader.result,
            imageName: array[array.length - 1],
          });
          getOrientation(file, (or) => {
            resetOrientation([reader.result], or, (baseImage) => {
              this.setState({
                imgSrc: baseImage,
                imageUpdated: this.props.editLibrary ? 1 : 0
              });
            });
          });
        };
      } else {
        toast.error('Please select image file less than 20MB');
        document.getElementById('create-challenge-file').value = '';
      }
    } else {
      toast.error(imageErrorMessage);
      document.getElementById('create-challenge-file').value = '';
    }
  };

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  removePhoto = () => {
    document.getElementById('create-challenge-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };

  changeDateTimeView = (value) => {
    if (this.state.showDateTimeInput === '') {
      this.setState({
        date: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        endDate: moment(moment().add(1, 'days')._d).format('MM/DD/YYYY'),
        time: '12:00 am',
        endTime: '11:59 pm',
        showDateTimeInput: value
      })
    } else {
      this.setState({
        date: moment(moment()._d).format('MM/DD/YYYY'),
        endDate: moment(moment()._d).format('MM/DD/YYYY'),
        time: moment().format('hh:mm a'),
        endTime: moment().format('hh:mm a'),
        showDateTimeInput: '',
      });
    }
  };

  onSelect = (name, value, key = '') => {
    const { challengePointKey } = this.state;
    (name === "activityChallengeType") ?
      this.setState({
        [name]: value,
        survivorChallenge: value === "Survivor challenge",
        groupChallenge: value === "Group Challenge",
        activityType: value === "Group Challenge" ? "Total Group Distance" : "Total Distance",
        challengePointKey: this.state.challengePointKey
      }) :
      this.setState({
        [name]: value,
        challengePointKey: key != '' ? key : challengePointKey
      });
  };

  onCompanySelect = async(name, value, /*key = ''*/) => {
    // const { challengePointKey } = this.state;
    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetCompanyTeamUsers}/${value.id}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res,
          [name]: value,
          // challengePointKey: key != '' ? key : challengePointKey,
          companies:[value.id]
        })
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }
    
  };

  getTeams = async(companyId)=>{

    const AuthToken = localStorage.getItem('AUTH_TOKEN');
    try {
      const res = await http.get(`${GetCompanyTeamUsers}/${companyId}`, {headers: {AUTHTOKEN: AuthToken}});
      if (res.message) {
        toast.error(res.message);
      }else{
        this.setState({
          allTeams:res
        })
      }
    }catch(error){
      toast.error(error.message);
      dispatch(networkError(error.message));
    }

  }


  

  changeDate = (e, stateName) => {
    const { showDateTimeInput } = this.state;
    if (showDateTimeInput === 'date') {
      this.setState({
        date: moment(e._d).format('MM/DD/YYYY'),
        time: moment(e._d).format('hh:mm a'),
        endDate: moment(e._d).format('MM/DD/YYYY'),
        endTime: '11:59 pm',
        openDateTimePicker: '',
      });
    } else if (stateName.includes('ate')) {
      if(stateName === 'date'){
        this.setState({
          openDateTimePicker: '',
          [stateName]: moment(e._d).format('MM/DD/YYYY'),
          endDate:moment(e._d).format('MM/DD/YYYY'),
        });
      }else {
        this.setState({
          openDateTimePicker: '',
          [stateName]: moment(e._d).format('MM/DD/YYYY'),
        });

      }
    } else {
      this.setState({
        [stateName]: moment(e._d).format('hh:mm a'),
        openDateTimePicker: stateName
      });
    }
  };

  showDatePicker = (value) => {
    if (this.state.openDateTimePicker === value) {
      this.setState({
        openDateTimePicker: ''
      });
    } else {
      this.setState({
        openDateTimePicker: value
      });
    }
  };
  checkBase64(image) {
    if (image) {
      if (image.includes('base64')) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  setIschallengeCreating(state){
    window.console.log("state", state);
    this.setState({isChallengeCreating:state});
  }
  onSubmit = (e) => {
    e.preventDefault();
    
    const { title, date, description, imgSrc, endDate, activityType, challengePointValue, challengeMode, weeklyIncrement,
      survivorAverage, imageName, companies, location, isSpouse, isDependent, isEmployee, challengeLength, survivorChallenge, groupChallenge, imageUpdated, emailOption, locationPermission, surveyId, selectedOption, dailyGoal, selectedDailyGoalOption, is_team_challenge, teams } = this.state;
    const { createChallenge, onHide, saveChallengeData, companyDetails, createActivityChallenge, createLibrary, activityChallengeById, editLibrary, editChallengeLibrary } = this.props;
    let survivorEndDate = moment(moment(date).add((challengeLength * 7) - 1, 'days')._d).format('MM/DD/YYYY');
    const challengeType = survivorChallenge ? 'survivor' : groupChallenge ? 'new_group' : 'group';
    const challenge = {
      title,
      companies: companies,
      body: description,
      image: imgSrc,
      event_type: groupChallenge ? activityType === 'Total Group Steps' ? 'Total Steps' : activityType === 'Total Group Calories' ? 'Total Calories' : 'Total Distance' : activityType,
      end_date: createLibrary || editLibrary ? null : (survivorChallenge || groupChallenge) ? moment(survivorEndDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss") : moment(endDate).format('YYYY-MM-DD') + ' ' + moment('11:59:59 PM', "hh:mm:ss a").format("HH:mm:ss"),
      start_date: createLibrary || editLibrary ? null : moment(date).format('YYYY-MM-DD') + ' ' + moment('12:00:00 AM', "hh:mm:ss a").format("HH:mm:ss"),
      survivor_average: survivorAverage,
      weekly_increment: survivorChallenge ? weeklyIncrement : null,
      challenge_point: challengePointValue,
      challenge_mode: survivorChallenge ? challengeMode : '',
      challenge_type: challengeType,
      isSurvivorChallenge: survivorChallenge,
      isGroupChallenge: groupChallenge,
      ...emailOption,
      survey_id: selectedOption === 1 ? null : surveyId,
      daily_goal: selectedDailyGoalOption === 1 ? null : dailyGoal,
      is_team_challenge:is_team_challenge, 
      teams:teams
    };
    // activityChallengeById ? challenge.convert_image = !this.checkBase64(imgSrc) : null;
    if(activityChallengeById){
      challenge.convert_image = !this.checkBase64(imgSrc)
    }
    if (editLibrary) {
      challenge.id = this.props.survivorChallengeData['id'];
      challenge.imageUpdated = imageUpdated
    }
    if (locationPermission) {
      challenge.company_city_states = location;
      challenge.challenge_for_spouse = isSpouse ? 1 : 0;
      challenge.challenge_for_dependent = isDependent ? 1 : 0
      if (this.props.locationDetails && this.props.locationDetails.length > 0) {
        if(location.length > 0){
          challenge.is_for_employee = 1
        }
        else{
          challenge.is_for_employee = 0
        }
      }
      else {
        if (companyDetails['spouse_or_dependent']) {
          challenge.is_for_employee = isEmployee ? 1 : 0
        }
        else {
          challenge.is_for_employee = 1
        }
      }
    }

    saveChallengeData({ ...challenge, imageName });
    activityChallengeById ? createChallenge(challenge, onHide, this.setIschallengeCreating) : createLibrary ? createActivityChallenge(challenge) : editChallengeLibrary(challenge);
  };

  onCheckboxChange = (element) => {
    const { companies } = this.state;
    if (companies.includes(element)) {
      let index = companies.findIndex((item) => item === element);
      if (index > -1) {
        companies.splice(index, 1);
      }
    } else {
      companies.push(element);
    }
    this.setState({
      companies: companies
    })
  };
  onTeamsCheckboxChange = (element) => {
    const { teams } = this.state;
    if (teams.includes(element)) {
      let index = teams.findIndex((item) => item === element);
      if (index > -1) {
        teams.splice(index, 1);
      }
    } else {
      teams.push(element);
    }
    this.setState({
      teams: teams
    })
  };
  onChangeEmail = (key) => {
    const { emailOption } = this.state;
    emailOption[key] = emailOption[key] === 0 ? 1 : 0;
    this.setState({ emailOption: emailOption });
  }

  selectAllCompanies = (e) => {
    e.preventDefault();
    let invitedCompanies = [];
    const { allCompanies } = this.props;
    if (this.state.companies.length === allCompanies.length) {
      invitedCompanies = [];
    } else {
      allCompanies.map((company) => {
        invitedCompanies.push(company.id);
      });
    }
    this.setState({
      companies: invitedCompanies
    })
  };

  selectAllTeams = (e) => {
    e.preventDefault();
    let invitedTeams = [];
    const { allTeams } = this.state;
    if (this.state.teams.length === allTeams.length) {
      invitedTeams = [];
    } else {
      allTeams.map((company) => {
        invitedTeams.push(company.id);
      });
    }
    this.setState({
      teams: invitedTeams
    })
  };

  isNumberKey = (evt) => {
    let charCode = (evt.which) ? evt.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) evt.preventDefault();
  };
  selectSpouseDependent = (stateName) => {
    this.setState((prev) => ({
      [stateName]: !prev[stateName]
    }))
  };
  toggleRadioButton = (e) => {
    this.setState({ selectedOption: parseInt(e.target.value,10) }, () => { });
  };

  toggleRadioGoalButton = (e) => {
    this.setState({ selectedDailyGoalOption: parseInt(e.target.value,10) }, () => { });
  };

  toggleRadioTeamButton = (e) => {
    this.setState({ is_team_challenge: parseInt(e.target.value,10) }, () => { });
  };

  onSelectSurveyType = (name, value, id) => {
    this.setState({
      [name]: value,
      surveyId: id
    });
  };
  render() {
    const { title, date, description, imageName, imgSrc, showDateTimeInput, endDate, activityType, challengePointValue,
      challengeMode, weeklyIncrement, survivorAverage, openDateTimePicker, companies, location, challengePoints, isSpouse, isDependent, isEmployee, challengeLength, survivorChallenge, groupChallenge, activityChallengeType, challengePointKey, emailOption, locationPermission, companiesPermission, selectedOption, surveyType, surveyId, dailyGoal, selectedDailyGoalOption, is_team_challenge, selectedCompany, allTeams,teams } = this.state;
    const { MandatoryFields, allCompanies, userCompany, locationDetails, companyDetails, createLibrary, editLibrary,
      surveyLibraries, t, survivorChallengeData, role } = this.props;
    let isDisabled = false;
    let emailOptionKeys = Object.keys(emailOption);
    const survivorChallengeLengthDropdown = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    MandatoryFields.map((field) => {
      if (!this.state[field] || !this.state.imgSrc || !this.state.title.trim() || !this.state.description.trim()) {
        isDisabled = true;
      }
    });
    if (companiesPermission && (!createLibrary && !editLibrary)) {
      if(this.state.is_team_challenge === 1){
        if(_.isEmpty(this.state.teams)){
          isDisabled = true;
        }

      }else if(_.isEmpty(this.state.companies)){
        isDisabled = true;
      }
      
    }
    if (locationPermission && (!createLibrary && !editLibrary)) {
      if (locationDetails && locationDetails.length > 0 && location.length < 1 && !(this.state.isSpouse || this.state.isDependent) && this.state.is_team_challenge !== 1) {
        isDisabled = true;
      }else if(_.isEmpty(this.state.teams) && this.state.is_team_challenge === 1){
        isDisabled = true;
      }
      if (locationDetails && locationDetails.length === 0 && companyDetails['spouse_or_dependent'] && this.state.is_team_challenge !== 1) {
        if (!(this.state.isSpouse || this.state.isDependent || this.state.isEmployee)) {
          isDisabled = true;
        }
      }
    }
    const activityTypes = groupChallenge ? groupActivityTypesItems : activityTypesItems;
    // const yesterday = moment().subtract( 0, 'day' );
    // console.log("yesterday",yesterday);
    // let nextYear = new Date(new Date().getFullYear(), 0, 1);
    // nextYear.setFullYear(nextYear.getFullYear() + 1);
    // console.log("nextYear", nextYear);
    // currentDate.isAfter(moment(new Date(nextYear)))
    const validEndDate = (currentDate) =>showDateTimeInput === 'date' ? currentDate.isAfter(moment().subtract( 1, 'day' )) : currentDate.isAfter(moment().subtract( 1, 'day' ));

    const valid = (currentDate) =>showDateTimeInput === 'date' ? currentDate.isAfter(moment(date).subtract( 1, 'day' )) : currentDate.isAfter(moment(date).subtract( 1, 'day' ));

    if ((companiesPermission && !allCompanies) || (locationPermission && !userCompany)) {
      return <Waiting />
    }
    return (
      <div>
        <TitleContainer>
          <FieldTitle>{t("Challenge Title")}:</FieldTitle>
          <CommonInputBox
            placeholder={t("Type title here...")}
            name="title"
            onChange={this.onChangeInput}
            value={title}
            maxLength="100"
          />
        </TitleContainer>
        <ImageContainer>
          <FieldTitle>{t("Challenge Image")}:</FieldTitle>
          <div>
            {imageName &&
              <div>
                <img src={this.checkBase64(imgSrc) ? imgSrc : `${ImageUrl}/${imgSrc}`} />
                <ImageContentContainer>
                  <div>{imageName}</div>
                  <RemovePhotoButton color="#aaaaaa" addMargin onClick={this.removePhoto}>{t("Remove photo")}</RemovePhotoButton>
                </ImageContentContainer>
              </div>
            }
            <ImageInput htmlFor="create-challenge-file" showAtBottom={imageName !== ''}>
              {imageName ? t('browse new') : t('browse')}
              <input
                id="create-challenge-file"
                type="file"
                name="user"
                multiple={false}
                onChange={(e) => this.onChange(e)}
                onClick={(e) => e.target.files[0] && this.onChange(e)}
                accept=".jpeg, .png, .jpg"
              />
            </ImageInput>
          </div>
        </ImageContainer>
        {
          (createLibrary || editLibrary) &&
          <ImageContainer>
            {/* <ActivityTypeContainer>
              <FieldTitle>{t("Challenge Types")}:</FieldTitle>
              <ActivityDropdown
                title={t(activityChallengeType)}
                id="dropdown-basic"
              >
                {
                  activityChallengeTypes.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={index}
                      onSelect={() => this.onSelect('activityChallengeType', item)}
                      active={activityChallengeType === item}
                    >
                      {t(item)}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer> */}
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Challenge Types")}
              title={t(activityChallengeType)}
              id="dropdown-basic"
              data={activityChallengeTypes}
              onSelect={this.onSelect}
              onSelectParameter={['activityChallengeType']}
              active={activityChallengeType}
              itemValue={false}
              activeValue={false}
              displayValue={false}
              t={t}
            />
          </ImageContainer>
        }
        <ImageContainer>
          {/* <ActivityTypeContainer>
            <FieldTitle>{t("Activity Type")}:</FieldTitle>
            <ActivityDropdown
              title={t(activityType)}
              id="dropdown-basic"
            >
              {
                activityTypes.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={index}
                    onSelect={() => this.onSelect('activityType', item)}
                    active={activityType === item}
                  >
                    {t(item)}
                  </CustomMenuItem>
                ))
              }
            </ActivityDropdown>
          </ActivityTypeContainer> */}
          <CommonDropdown
            type={'challangeDropdown'}
            labelText={t("Activity Type")}
            title={t(activityType)}
            id="dropdown-basic"
            data={activityTypes}
            onSelect={this.onSelect}
            onSelectParameter={['activityType']}
            active={activityType}
            itemValue={false}
            activeValue={false}
            displayValue={false}
            t={t}
          />
          <SurvivorChallengeSection>
            {
              (survivorChallenge || groupChallenge) &&
              <SurvivorAverage>
                <FieldTitle>{groupChallenge ? t('Team Goal:') : t('Survivor Average:')}</FieldTitle>
                <CommonInputBox
                  type='number'
                  name='survivorAverage'
                  onChange={this.onChangeInput}
                  value={activityType.includes('Distance') ? parseFloat(survivorAverage) : parseInt(survivorAverage,10)}
                  pattern='[0-9]*'
                  min="0"
                  onKeyPress={(e) => this.isNumberKey(e)}
                />
              </SurvivorAverage>
            }
            {survivorChallenge &&
              <SurvivorAverage>
                <FieldTitle>{t("Weekly Increment")}:</FieldTitle>
                <CommonInputBox
                  type='number'
                  name="weeklyIncrement"
                  value={weeklyIncrement}
                  onChange={this.onChangeInput}
                  pattern='[0-9]*'
                  min="0"
                  onKeyPress={(e) => this.isNumberKey(e)}
                />
              </SurvivorAverage>
            }
          </SurvivorChallengeSection>
        </ImageContainer>
        {survivorChallengeData.challenge_type === 'group'?
          <React.Fragment>          
            <ImageContainer>
              <FirstPart>
                <FieldTitle>{t("Would You Like to add a daily goal for this challenge")}:</FieldTitle>
                <RadioButtonContainer justifyContent={"start"} style={{margin:'0px',paddingTop:'0px'}}>
                  <RadioButton id="3" handler={this.toggleRadioGoalButton} value={0} isChecked={selectedDailyGoalOption === 0} label={t('Yes')} challengeLeaderBoard={true} />
                  <RadioButton id="4" handler={this.toggleRadioGoalButton} value={1} isChecked={selectedDailyGoalOption === 1} label={t('No')} challengeLeaderBoard={true} />
                </RadioButtonContainer>
              </FirstPart>
            </ImageContainer>
          </React.Fragment> : null}
        {selectedDailyGoalOption === 0 && survivorChallengeData.challenge_type === 'group' ?
          <TitleContainer>
            <FieldTitle>{t("Individual Daily Goal")}:</FieldTitle>
            <CommonInputBox
              placeholder={t("Enter Your Daily Goal")}
              name="dailyGoal"
              onChange={this.onChangeInput}
              value={dailyGoal}
              type="number"
              maxLength="10"
            />
          </TitleContainer>
          : null}
        <ImageContainer>
          {/* <ActivityTypeContainer>
            <FieldTitle>{t("Challenge Point Value")}:</FieldTitle>
            <ActivityDropdown
              title={t(challengePointKey)}
              id="dropdown-basic"
            >
              {
                challengePoints.map((item, index) => (
                  <CustomMenuItem
                    eventKey={index}
                    key={`${item.key}-${index}`}
                    onSelect={() => this.onSelect('challengePointValue', item['value'], item['key'])}
                    active={challengePointValue == item['value']}
                  >
                    {t(item['key'])}
                  </CustomMenuItem>
                ))
              }
            </ActivityDropdown>
          </ActivityTypeContainer> */}
          <CommonDropdown
            type={'challangeDropdown'}
            labelText={t("Challenge Point Value")}
            title={t(challengePointKey)}
            id="dropdown-basic"
            data={challengePoints}
            onSelect={this.onSelect}
            onSelectParameter={['challengePointValue','value','key']}
            active={challengePointValue}
            itemValue={true}
            activeValue={true}
            displayValue={true}
            valueIndex={2}
            t={t}
          />
          
          {
            survivorChallenge &&
            // <ActivityTypeContainer challengeMode>
            //   <FieldTitle>{t("Challenge Mode:")}</FieldTitle>
            //   <ActivityDropdown
            //     title={t(challengeMode)}
            //     id="dropdown-basic"
            //   >
            //     {
            //       challengeModeItems.map((item, index) => (
            //         <CustomMenuItem
            //           eventKey={index}
            //           key={index}
            //           onSelect={() => this.onSelect('challengeMode', item)}
            //           active={challengeMode === item}
            //         >
            //           {t(item)}
            //         </CustomMenuItem>
            //       ))
            //     }
            //   </ActivityDropdown>
            // </ActivityTypeContainer>
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Challenge Mode")}
              title={t(challengeMode)}
              id="dropdown-basic"
              data={challengeModeItems}
              onSelect={this.onSelect}
              onSelectParameter={['challengeMode']}
              active={challengeMode}
              itemValue={false}
              activeValue={false}
              displayValue={false}
              t={t}
            />
          }
        </ImageContainer>



        {(!createLibrary && !editLibrary) &&
          <TitleContainer addMargin>
            <FieldTitle>{t("Challenge Date:")}</FieldTitle>
            {showDateTimeInput === 'date' ?
              <InputContainer>
                <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                  <StyledDateTime
                    open={openDateTimePicker === 'date'}
                    inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true, }}
                    dateFormat="MM/DD/YYYY"
                    closeOnSelect={true}
                    closeOnTab={true}
                    timeFormat={false}
                    value={date}
                    onChange={(e) => this.changeDate(e, 'date')}
                    isValidDate={valid}
                  />
                  <DateIconContainer onClick={() => this.showDatePicker('date')}>
                    <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                  </DateIconContainer>
                </div>
              </InputContainer> :
              <div>
                <StartDateContainer isSurvivorChallenge={survivorChallenge}>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'date' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'date'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true }}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={date}
                        onChange={(e) => this.changeDate(e, 'date')}
                        isValidDate={validEndDate}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('date')}>
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainer>
                <DateJoiner isSurvivorChallenge={survivorChallenge}>{t("to")}</DateJoiner>
                {!survivorChallenge && !groupChallenge ? <StartDateContainer isSurvivorChallenge={survivorChallenge}>
                  <InputContainer>
                    <div ref={(node) => openDateTimePicker === 'endDate' && this.setDatePickerWrapper(node)}>
                      <StyledDateTime
                        open={openDateTimePicker === 'endDate'}
                        inputProps={{ placeholder: moment().format('MM/DD/YYYY'), readOnly: true }}
                        dateFormat="MM/DD/YYYY"
                        closeOnSelect={true}
                        closeOnTab={true}
                        timeFormat={false}
                        value={endDate}
                        onChange={(e) => this.changeDate(e, 'endDate')}
                        isValidDate={valid}
                      />
                      <DateIconContainer onClick={() => this.showDatePicker('endDate')} >
                        <LazyImage src={ImageUrl + "/images/calender.png"} alt="calender"/>
                      </DateIconContainer>
                    </div>
                  </InputContainer>
                </StartDateContainer> : null
                }
                {survivorChallenge || groupChallenge ?
                  <ActivityTypeContainer float={'left'} width={'50%'} margin={'-15px 0 0 0'}>
                    <ActivityDropdown
                      title={`${t(challengeLength)} ${t("Weeks")}`}
                      id="dropdown-basic"
                    >
                      {survivorChallengeLengthDropdown.map((item, index) => (
                        <CustomMenuItem
                          eventKey={index}
                          key={index}
                          onSelect={() => this.onSelect('challengeLength', item)}
                          active={challengeLength === item}
                        >
                          {`${t(item)} ${t("Weeks")}`}
                        </CustomMenuItem>
                      ))}
                    </ActivityDropdown>
                  </ActivityTypeContainer> : null}
              </div>
            }
          </TitleContainer>
        }

        {role==="ADMIN" && (!createLibrary && !editLibrary) && !survivorChallenge && !groupChallenge &&  <ImageContainer>
          <FirstPart>
            <FieldTitle>{t("Would you like to launch a team challenge?")}</FieldTitle>
            <RadioButtonContainer justifyContent={"start"} style={{margin:'0px',paddingTop:'0px'}}>
              <RadioButton id="5" handler={this.toggleRadioTeamButton} value={1} isChecked={is_team_challenge === 1} label={t('Yes')} challengeLeaderBoard={true} />
              <RadioButton id="6" handler={this.toggleRadioTeamButton} value={0} isChecked={is_team_challenge === 0} label={t('No')} challengeLeaderBoard={true} />
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer>}          
        {is_team_challenge == 1 && role==="ADMIN" && (
          <ImageContainer>
            {/* <ActivityTypeContainer>
              <FieldTitle>{t("Select Company")}:</FieldTitle>
              <ActivityDropdown
                title={t(selectedCompany.company_name)}
                id="dropdown-basic"
              >
                {
                  allCompanies.map((item, index) => (
                    <CustomMenuItem
                      eventKey={index}
                      key={`${item.key}-${index}`}
                      onSelect={() => this.onCompanySelect('selectedCompany', item, item['key'])}
                      active={selectedCompany == item['company_name']}
                    >
                      {t(item['company_name'])}
                    </CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer> */}
            <CommonDropdown
              type={'challangeDropdown'}
              labelText={t("Select Company")}
              title={t(selectedCompany.company_name)}
              id="dropdown-basic"
              data={allCompanies}
              onSelect={this.onCompanySelect}
              onSelectParameter={['selectedCompany',"company_name","key"]}
              active={selectedCompany}
              activeValue={true}
              itemValue={false}
              displayValue={true}
              valueIndex={1}
              t={t}
            />
          </ImageContainer>

        )}


        {
          (companiesPermission) && (!createLibrary && !editLibrary) && is_team_challenge !== 1 &&
          <AmigosContainer>
            <TopContainer>
              <FieldTitle>{t("Invite Companies:")}</FieldTitle>
              <ButtonContainer>
                <CommonButton styles={{color:"#159fc9"}} onClick={(e) => this.selectAllCompanies(e)}
                  btnType={"rounded"}
                  icon={<i className="fas fa-check" />}
                  title={companies.length === allCompanies.length ? t('deselect all companies') : t('select all companies here')}
                />
                {/* <SaveButton color="#159fc9" onClick={(e) => this.selectAllCompanies(e)}>
                  <i className="fas fa-check" />
                  {companies.length === allCompanies.length ? t('deselect all companies common') : t('select all companies here common')}
                </SaveButton>   */}
              </ButtonContainer>
            </TopContainer>
            <InviteContainer>
              <ScrollableContainer>
                {
                  allCompanies.map((company, index) => (
                    <InviteAmigo key={index}>
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={companies.includes(company.id)}
                          onChange={() => this.onCheckboxChange(company.id)}
                        />
                        <CheckMark checked={companies.includes(company.id)} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => this.onCheckboxChange(company.id)}>
                        <span>{t(company.company_name)}</span>
                      </NameContainer>
                    </InviteAmigo>
                  ))
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer>
        }
        {role != "ADMIN" && (!createLibrary && !editLibrary) &&  <ImageContainer style={{borderBottom:'none',paddingBottom:'0px'}}>
          <FirstPart>
            <FieldTitle>{t("Who would you like to invite?")}</FieldTitle>
            <RadioButtonContainer justifyContent={"start"} style={{margin:'0px',paddingTop:'0px'}}>
              {/* <RadioButton id="7" disabled={allTeams.length === 0} handler={this.toggleRadioTeamButton} value={1} isChecked={is_team_challenge == 1} label={t('Teams')} challengeLeaderBoard={true} /> */}
              <RadioButton id="8" handler={this.toggleRadioTeamButton} value={0} isChecked={is_team_challenge == 0} label={t('Location')} challengeLeaderBoard={true} />
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer>}
        {
          (!createLibrary && !editLibrary) && is_team_challenge == 1 &&
          <AmigosContainer>
            <TopContainer>
              <FieldTitle>{t("Invite Teams:")}</FieldTitle>
              <ButtonContainer>
                <CommonButton styles={{color:"#159fc9"}} onClick={(e) => this.selectAllTeams(e)}
                  btnType={"rounded"}
                  icon={<i className="fas fa-check" />}
                  title={teams.length === allTeams.length ? t('deselect all companies') : t('select all companies')}
                />
                {/* <SaveButton color="#159fc9" onClick={(e) => this.selectAllTeams(e)}>
                  <i className="fas fa-check" />
                  {
                    teams.length === allTeams.length ? t('deselect all companies') : t('select all companies')
                  }
                </SaveButton> */}
              </ButtonContainer>
            </TopContainer>
            <InviteContainer>
              <ScrollableContainer>
                {
                  allTeams.map((team, index) => team.name && (
                    <InviteAmigo key={index}>
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={teams.includes(team.id)}
                          onChange={() => this.onTeamsCheckboxChange(team.id)}
                        />
                        <CheckMark checked={teams.includes(team.id)} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => this.onTeamsCheckboxChange(team.id)}>
                        <span>{t(team.name)}</span>
                      </NameContainer>
                    </InviteAmigo>)
                  )
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer>
        }
        {(!createLibrary && !editLibrary) && (locationPermission) && (locationDetails && locationDetails.length > 0 || companyDetails['spouse_or_dependent'])  && is_team_challenge != 1 ?
          <AmigosContainer>
            <TopContainer>
              <FieldTitle> {t("Company Locations:")}</FieldTitle>
              <ButtonContainer>
                {locationDetails && locationDetails.length > 0 ?
                  <CommonButton styles={{color:"#159fc9"}} onClick={(e) => this.selectAllLocation(e, locationDetails)}
                    btnType={"rounded"}  
                    icon={<i className="fas fa-check" />}
                    title={location.length === locationDetails.length ? t('deselect all locations') : t('select all locations')}
                  />
                  // <SaveButton color="#159fc9" onClick={(e) => this.selectAllLocation(e, locationDetails)}>
                  //   <i className="fas fa-check" />
                  //   {
                  //     location.length === locationDetails.length ? t('deselect all locations here') : t('select all locations here')
                  //   }
                  // </SaveButton> 
                  : null
                }
              </ButtonContainer>
            </TopContainer>
            <InviteContainer>
              <ScrollableContainer>
                <LocationContainer minHeight={locationDetails && locationDetails.length}>
                  {
                    locationDetails && locationDetails.length > 0 ? locationDetails.map((loc, index) => (

                      <InviteAmigo key={index}>
                        <CustomCheckbox>
                          <input
                            type="checkbox"
                            checked={location.includes(loc.id)}
                            onChange={() => this.onChangeLocation(loc.id)}
                          />
                          <CheckMark checked={location.includes(loc.id)} />
                        </CustomCheckbox>
                        <NameContainer onClick={() => this.onChangeLocation(loc.id)}>
                          <span>{t(loc.location)}</span>
                        </NameContainer>
                      </InviteAmigo>
                    )) : null
                  }
                </LocationContainer>
                {companyDetails['spouse_or_dependent'] ?
                  <div style={{ display: 'flex' }}>
                    <InviteAmigo>
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={isSpouse}
                          onChange={() => this.selectSpouseDependent('isSpouse')}
                        />
                        <CheckMark checked={isSpouse} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => this.selectSpouseDependent('isSpouse')}>
                        <span>{t('Spouse')}</span>
                      </NameContainer>
                    </InviteAmigo>
                    <InviteAmigo>
                      <CustomCheckbox>
                        <input
                          type="checkbox"
                          checked={isDependent}
                          onChange={() => this.selectSpouseDependent('isDependent')}
                        />
                        <CheckMark checked={isDependent} />
                      </CustomCheckbox>
                      <NameContainer onClick={() => this.selectSpouseDependent('isDependent')}>
                        <span>{t('Dependent')}</span>
                      </NameContainer>
                    </InviteAmigo>
                    {locationDetails && locationDetails.length == 0 &&
                      <InviteAmigo>
                        <CustomCheckbox>
                          <input
                            type="checkbox"
                            checked={isEmployee}
                            onChange={() => this.selectSpouseDependent('isEmployee')}
                          />
                          <CheckMark checked={isEmployee} />
                        </CustomCheckbox>
                        <NameContainer onClick={() => this.selectSpouseDependent('isEmployee')}>
                          <span>{t('Employee')}</span>
                        </NameContainer>
                      </InviteAmigo>
                    }
                  </div> : null
                }
              </ScrollableContainer>
            </InviteContainer>
          </AmigosContainer> : null
        }

        {
          (!createLibrary && !editLibrary) &&
          <TitleContainer>
            <FieldTitle>{t("Email Notifications: Select email option for this Challenge")} </FieldTitle>
            <LocationContainer>

              {
                emailOptionKeys.map((item, index) => (
                  <InviteAmigo key={index} width={'200px'}>
                    <CustomCheckbox>
                      <input
                        type="checkbox"
                        checked={emailOption[item] === 1}
                        onChange={() => this.onChangeEmail(item)}
                      />
                      <CheckMark checked={emailOption[item] === 1} />
                    </CustomCheckbox>
                    <NameContainer onClick={() => this.onChangeEmail(item)}>
                      <span>{t("Challenge")}{item == 'launch_challenge_email' ? t(' Creation') : item == 'challenge_reminder_email' ? t(' Reminder') : t(' Completion')} </span>
                    </NameContainer>
                    <div className="showInfoText">{showChallengeEmailNotificationInfo[index]}</div>
                  </InviteAmigo>
                ))
              }
            </LocationContainer>
          </TitleContainer>
        }
        <TitleContainer>
          <FieldTitle>{t("About the Challenge")}:</FieldTitle>
          <CommonTextArea
            placeholder={t("Write about challenge here...")}
            rows="8"
            name="description"
            value={description}
            onChange={this.onChangeInput}
            data-gramm_editor="false"
          />
        </TitleContainer>

        {(createLibrary || editLibrary) || (surveyId && this.props.activityChallengeById) ? <ImageContainer>
          <FirstPart>
            <FieldTitle>{t("Add a survey")}:</FieldTitle>
            <RadioButtonContainer justifyContent={"start"} style={{margin:'0px',paddingTop:'0px'}}>
              <RadioButton id="1" handler={this.toggleRadioButton} value={0} isChecked={selectedOption == 0} label={t('Yes')} challengeLeaderBoard={true} />
              <RadioButton id="2" handler={this.toggleRadioButton} value={1} isChecked={selectedOption == 1} label={t('No')} challengeLeaderBoard={true} />
            </RadioButtonContainer>
          </FirstPart>
        </ImageContainer> : null}
        {selectedOption == 0 ? <TitleContainer addMargin><ActivityTypeContainer width="100%" noChildWidth={1}>
          <FieldTitle>{t("Select a survey")}:</FieldTitle>
          <ActivityDropdown
            title={<div className="title">{surveyLibraries.length == 0 ? t('No libraries') : surveyType == '' ? t('Select a library') : surveyType}</div>}
            id={"surveyType"}
            disabled={this.props.activityChallengeById}
          >{
              surveyLibraries && surveyLibraries.length > 0 && surveyLibraries.map((list) => (
                <CustomMenuItem key={list.id} active={surveyType == list.survey_name} onSelect={() => this.onSelectSurveyType('surveyType', list.survey_name, list.id)}>{t(list.survey_name)}</CustomMenuItem>
              ))
            }
          </ActivityDropdown>
        </ActivityTypeContainer>
        </TitleContainer> : null}
        <ImageContainer>
          {/* <SaveButton
            addMargin
            disabled={isDisabled}
            onClick={this.onSubmit}
          >
            {t("Create Challenge")}
          </SaveButton> */}
          <CommonButton
            btnType={"rounded"}
            disabled={isDisabled || this.state.isChallengeCreating}
            onClick={this.onSubmit}
            title={this.state.isChallengeCreating ?  <Spinner color={"white"} style={{display:"flex",justifyContent:"center",}}/> : (editLibrary ? t("Update Challenge") : t("Create Challenge") )}
            addMargin
            styles={{width:'158px', height:'35px',padding:'0px',marginBottom:'55px'}}
            style={{display:"flex",justifyContent:"center", alignItems:"center"}}
          />
        </ImageContainer>
      </div>
    )
  }
}

CreateChallengeForm.propTypes = {
  isSurvivorChallenge: PropTypes.bool.isRequired,
  createChallenge: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onHide: PropTypes.func,
  MandatoryFields: PropTypes.array.isRequired,
  survivorChallengeData: PropTypes.object,
  saveChallengeData: PropTypes.func.isRequired,
  allCompanies: PropTypes.array,
  fetchAllCompanies: PropTypes.func.isRequired,
  role: PropTypes.string,
  fetchCompanyForUser: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  locationDetails: PropTypes.array,
  companyDetails: PropTypes.object,
  allowChallengeEventForSpouseDependent: PropTypes.func,
  isGroupChallenge: PropTypes.bool,
  activityChallengeById: PropTypes.bool,
  createActivityChallenge: PropTypes.func,
  createLibrary: PropTypes.bool,
  editChallengeLibrary: PropTypes.func,
  editLibrary: PropTypes.bool,
  userPermissions: PropTypes.array,
  surveyLibraries: PropTypes.array,
  getSurveyLibraries: PropTypes.func,
  t: PropTypes.func,
  fetchCompanyTeamUsers:PropTypes.func
};

const mapStateToProps = (state) => ({
  allCompanies: state.challenges.allCompanies,
  userCompany: state.profileData.userCompany,
  locationDetails: state.register.locationDetails,
  companyDetails: state.profileData.companyDetails,
  userPermissions: state.profileData.userPermissions,
  surveyLibraries: state.surveys.surveyLibraries,
});

const mapDispatchToProps = (dispatch) => ({
  saveChallengeData: (data) => dispatch(saveChallengeData(data)),
  fetchAllCompanies: () => dispatch(getAllCompanies()),
  fetchCompanyForUser: () => dispatch(getUserCompanyDetails()),
  allowChallengeEventForSpouseDependent: () => dispatch(allowChallengeEventForSpouseDependent),
  editChallengeLibrary: (challenge) => dispatch(editChallengeLibrary(challenge)),
  getSurveyLibraries: () => dispatch(getSurveyLibraries()),
  fetchCompanyTeamUsers: (companyId) => dispatch(fetchCompanyTeamUsers(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateChallengeForm));
